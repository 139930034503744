<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs"
         v-on="on"
         href="#"
         @click.prevent
      >
        <div class="edit">
          <v-icon
              @click.prevent=""
              class="icon-item icon-briefcase-eye-outline"
          ></v-icon>
        </div>
      </a>
    </template>
    <AnalyticalReportConfigDialog
        v-if="dialog"
        :config="config"
        @close="dialog = false"
    ></AnalyticalReportConfigDialog>
  </v-dialog>
</template>

<script>
import AnalyticalReportConfigDialog from "./AnalyticalReportConfigDialog.vue";

export default {
  name: 'PassportIndicatorEditButton',
  components: {AnalyticalReportConfigDialog},
  props: ['config'],
  data: () => ({
    dialog: false
  }),
  methods: {
  },
  async beforeMount() {
  }
}
</script>
